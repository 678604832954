"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
Object.defineProperty(exports, "__esModule", { value: true });
var ajax_1 = require("./ajax");
var FlashMessage_1 = require("./FlashMessage");
var TOO_YOUNG_BABY_MSG = 'Baby is too young';
var supportsDateInput = function () {
    var input = document.createElement('input');
    input.setAttribute('type', 'date');
    var notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);
    return (input.value !== notADateValue);
};
exports.getDate = (supportsDateInput() ? function (value) {
    var splitted = value.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    return new Date(parseInt(splitted[1]), parseInt(splitted[2]) - 1, parseInt(splitted[3]));
} : function (value) {
    var splitted = value.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    return new Date(parseInt(splitted[3]), parseInt(splitted[2]) - 1, parseInt(splitted[1]));
});
exports.postForm = function (data) { return __awaiter(_this, void 0, void 0, function () {
    var dataObject, postalCodeSegments, postData, babyBirthDateSpecial, response_1, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dataObject = data.reduce(function (acc, input) {
                    if (acc[input.name]) {
                        return acc;
                    }
                    acc[input.name] = input.value;
                    return acc;
                }, {});
                postalCodeSegments = dataObject.PostalCode.split(' - ');
                postData = {
                    FirstName: dataObject.FirstName,
                    LastName: dataObject.LastName,
                    Email: dataObject.Email,
                    PostalCode: postalCodeSegments[0],
                    City: postalCodeSegments[1],
                    BabyWhenTheLight: exports.getDate(dataObject.BabyWhenTheLight),
                    OptIn: dataObject.OptIn === 'true',
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                babyBirthDateSpecial = new Date((new Date()).getTime() - 4 /* months */ * 30 /* days */ * 24 /* hours */ * 3600 /* seconds */ * 1000 /* ms */);
                if (babyBirthDateSpecial.getTime() < postData.BabyWhenTheLight.getTime()) {
                    console.info('Baby is less than 4 months old');
                    $('#modalNewBaby').modal('show');
                    $('.offer').each(function () { ajax_1.disabledSelected(this); });
                    FlashMessage_1.FlashMessage.instance.addFlash('info', 'Age invalide', 'Votre bébé doit avoir minimum 4 mois.');
                    throw new Error(TOO_YOUNG_BABY_MSG);
                }
                return [4 /*yield*/, ajax_1.postAjax('/checkavailableoffers', postData)];
            case 2:
                response_1 = _a.sent();
                if (response_1.status !== 'success') {
                    throw new Error('Non-success server response' + (response_1.message ? ': ' + response_1.message : ''));
                }
                $('.offer').filter(function () {
                    var offerId = $(this).data('offer-id');
                    return response_1.offers.indexOf(offerId) === -1;
                }).each(function () {
                    ajax_1.disabledSelected(this);
                });
                return [2 /*return*/, true];
            case 3:
                error_1 = _a.sent();
                if (error_1 && error_1.responseJSON && error_1.responseJSON.type === 'Invalid form') {
                    FlashMessage_1.FlashMessage.instance.addFlash('warning', 'Formulaire invalide', 'Le formulaire est invalide. Veuillez vérifier tous les champs, et réessayez.', new Error('Invalid form'));
                }
                else if (error_1.message === TOO_YOUNG_BABY_MSG) {
                    return [2 /*return*/, true];
                }
                else {
                    FlashMessage_1.FlashMessage.instance.addFlash('danger', 'Une erreur est survenue!', 'Une erreur est survenue lors de l\'envoi du formulaire. Rechargez la page et réessayez. Si le problème persiste, veuillez nous le signaler.', error_1);
                }
                return [2 /*return*/, false];
            case 4: return [2 /*return*/];
        }
    });
}); };
var doSearch = function (search) { return __awaiter(_this, void 0, void 0, function () {
    var searchRes, ids;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ajax_1.getAjax("/completezipcode?search=" + encodeURIComponent(search))];
            case 1:
                searchRes = _a.sent();
                ids = [];
                return [2 /*return*/, searchRes.sort(function (a, b) {
                        if (a.id < b.id)
                            return -1;
                        if (a.id > b.id)
                            return 1;
                        return 0;
                    }).reduce(function (acc, item) {
                        if (ids.indexOf(item.id) === -1) {
                            ids.push(item.id);
                            acc.push(item);
                        }
                        return acc;
                    }, [])];
        }
    });
}); };
var $validatedPostalCode = $('#IsValidatedPostalCode');
var $autocompleteList = $('#PostalCodesList');
var $postalCodeInput = $('#PostalCode');
var hideAutocomplete = function () { return $autocompleteList.removeClass('active'); };
$postalCodeInput.on('keyup focusin', function () {
    return __awaiter(this, void 0, void 0, function () {
        var search, results, content, $parsedContent;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    $validatedPostalCode.val('');
                    search = this.value;
                    if (!(search.length > 2)) return [3 /*break*/, 2];
                    return [4 /*yield*/, doSearch(search)];
                case 1:
                    results = _a.sent();
                    $autocompleteList.empty();
                    content = results.reduce(function (acc, item) {
                        var segments = item.label.match(/(\d+)\s*-\s*(.+$)/);
                        if (!segments) {
                            return acc;
                        }
                        return acc + ("<p><b>" + segments[1] + "</b><span>" + segments[2] + "</span></p>");
                    }, '');
                    $parsedContent = $($.parseHTML(content));
                    $autocompleteList.append($parsedContent);
                    $autocompleteList.addClass('active');
                    $(document).one('click touch', hideAutocomplete);
                    $parsedContent.click(function () {
                        var $elem = $(this);
                        $postalCodeInput.val($elem.find('b').text() + ' - ' + $elem.find('span').text());
                        $validatedPostalCode.val('Ok');
                        $(document).off('click touch', hideAutocomplete);
                        $autocompleteList.removeClass('active');
                        $autocompleteList.css({ display: 'none' });
                        setTimeout(function () {
                            $autocompleteList.css({ display: '' });
                        }, 100);
                    });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
});

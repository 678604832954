"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../styles/theme.scss");
require("../images/BLE_Logo.png");
require("../images/cado.png");
require("../images/loader.gif");
require("babel-polyfill");
require("./ux");
require("./idsoft");
var FlashMessage_1 = require("./FlashMessage");
window.addEventListener('unhandledrejection', function (event) {
    FlashMessage_1.FlashMessage.instance.addFlash('danger', 'Erreur non gérée.', 'Une erreur non géré est. Rechargez la page et réessayez. Si le problème persiste, veuillez nous le signaler.', event.reason);
});
window.addEventListener('error', function (event) {
    FlashMessage_1.FlashMessage.instance.addFlash('danger', 'Erreur non gérée.', 'Une erreur non géré est. Rechargez la page et réessayez. Si le problème persiste, veuillez nous le signaler.', event.reason);
});
window.FlashMessage = FlashMessage_1.FlashMessage;

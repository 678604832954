"use strict";
var getQueryVariable = function (variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
};
var idSoft = getQueryVariable('idsoft');
var navTypeOk = window.performance ? performance.navigation.type === 0 : true;
if (!window.performance) {
    console.warn('Current browser does not support tracking requirements, it may cause troubles');
}
if (navTypeOk && window.history.length <= 1 && idSoft) {
    var now = new Date().getTime();
    var expirationStamp = now + 6 /*h*/ * 60 /*m*/ * 60 /*s*/ * 1000 /*ms*/;
    var cookieLine = "idSoft=" + idSoft + "; expires=" + (new Date(expirationStamp)).toUTCString() + "; path=/";
    document.cookie = cookieLine;
}

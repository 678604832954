"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ajax_1 = require("./ajax");
var registerForm_1 = require("./registerForm");
var validateCoupons_1 = require("./validateCoupons");
$(document).ready(function () {
    //($('#intro') as any).parallax({imageSrc: '/Content/dist/images/bg-header-dsk@3x.jpg'});
    //on donne une taille au header
    var wHeight = $(window).height();
    $("#intro .container").height(wHeight);
    $('.montant')
        .html(ajax_1.toMoney($('.offer')
        .toArray()
        .reduce(function (sum, offer) { return sum + parseFloat($(offer).data('price')); }, 0)));
    // smooth scroll ------------------------------------------------------------------------
    $("a.smooth[href^='#']").on('click', function (e) {
        // alert('clic');
        // prevent default anchor click behavior
        e.preventDefault();
        var targetOffset = $(window.location.hash).offset();
        if (!targetOffset) {
            return;
        }
        // animate
        $('html, body').animate({
            scrollTop: targetOffset.top
        }, "slow", function () {
            // when done, add hash to url
            // (default click behaviour)
            // window.location.hash = this.hash;
        });
    });
    // fixer la barre de Compteur au scroll -------------------------------------------------
    var fixCompteur = function () {
        var wrap = $("#blocReduction");
        $(window).scroll(function () {
            var elementPosition = $('#intro').height();
            var wWidth = $(window).width();
            var scrollTop = $(window).scrollTop();
            //console.log('wWidth : ' + wWidth);
            if (typeof elementPosition === 'undefined') {
                return;
            }
            // BERSION MOBILE, avec menu fixe
            if (wWidth < 768) {
                if (scrollTop > (elementPosition - 5)) {
                    wrap.addClass("fixed");
                }
                else {
                    wrap.removeClass("fixed");
                }
            }
            else if ((wWidth > 769) && (wWidth < 992)) {
                if (scrollTop > (elementPosition - 50)) {
                    wrap.addClass("fixed");
                }
                else {
                    wrap.removeClass("fixed");
                }
            }
            else if (wWidth >= 992) {
                if (scrollTop > (elementPosition - 0)) {
                    wrap.addClass("fixed");
                }
                else {
                    wrap.removeClass("fixed");
                }
            }
        });
    };
    fixCompteur();
    // Les actions sur les BR ---------------------------------------------------------------
    // ON AJOUTE LE BR
    $("#offers .toSelect").click(function (event) {
        event.preventDefault();
        ajax_1.toggleOfferSelect(parseInt($(this).closest('.offer').data('offer-id')), true);
    });
    // ON ENLEVE LE BR
    $("#offers .toUnselect").click(function (event) {
        event.preventDefault();
        ajax_1.toggleOfferSelect(parseInt($(this).closest('.offer').data('offer-id')), false);
    });
    // lancer la modal CGU + tooltip --------------------------------------------------------
    $(".linkToCGU").click(function () {
        // cache la modal au cas ou il y en a une;
        $('#modalFelicitation').modal('hide');
        // cache la tolltip au cas ou il y en a une;
        $('[data-toggle="tooltip"]').tooltip('hide');
        //  lance la modal
        $('#modalCGU').modal('show');
    });
    //Tooltip bootstrap ---------------------------------------------------------------------
    $('[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        html: true
    });
    // height de la modal mentions -----------------------------------------------------
    function resizeModal() {
        var newWinHeight = $(window).height();
        // console.log('lance function');
        // console.log(wHeight);
        if (newWinHeight < 750) {
            $('.classicModal .modal-body .scrollable').css('maxHeight', (newWinHeight - 350)).css('overflow-y', 'auto');
        }
        else {
            $('.classicModal .modal-body .scrollable').css('maxHeight', 400).css('overflow-y', 'auto');
        }
    }
    resizeModal();
    $(window).resize(function () {
        resizeModal();
        fixCompteur();
    });
    // scrollTop pour remonter ds la page (modal, alertre etc.) ------------------------------
    function scrollTopTop() {
        // on scroll vers le haut por voir le message
        var myWindow = $('window, html, body', window.parent.document);
        $(myWindow).animate({ scrollTop: 0 }, 'slow');
        //console.log('au top');
    }
    // remonter vers la modal ---------------------------------------------------------------
    $("#btnImprim").click(function () {
        //on remonte la page
        scrollTopTop();
    });
    var $buttons = $('#doPrint, #doSendMail');
    $('#doPrint').click(function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        event.stopPropagation();
                        $buttons.attr('disabled', 'disabled');
                        url = $(this).data('link-url');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, validateCoupons_1.printCoupons(url, event)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        $buttons.attr('disabled', '');
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    });
    $('#doSendMail').click(function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        event.stopPropagation();
                        $buttons.attr('disabled', 'disabled');
                        url = $(this).data('link-url');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, validateCoupons_1.sendMailCoupons(url, event)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        $buttons.attr('disabled', '');
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    });
    // VALIDATION BOOTSTRAP -----------------------------------------------------------------
    // SRC : http://1000hz.github.io/bootstrap-validator/
    var submitButton = document.querySelector('#submit_connexion');
    var $form = $('#form_creation');
    $form
        .validator({
        custom: {
            // Validate baby @ least 4 months old
            age: function ($el) {
                try {
                    var date = registerForm_1.getDate($el[0].value);
                    var babyBirthDateSpecial = new Date((new Date()).getTime() - 4 /* months */ * 30 /* days */ * 24 /* hours */ * 3600 /* seconds */ * 1000 /* ms */);
                    if (babyBirthDateSpecial.getTime() < date.getTime()) {
                        return 'Too young';
                    }
                }
                catch (_a) {
                    return 'Invalid';
                }
            },
            agemax: function ($el) {
                try {
                    var date = registerForm_1.getDate($el[0].value);
                    var babyBirthDateSpecial = new Date((new Date()).getTime() - 72 /* months */ * 30 /* days */ * 24 /* hours */ * 3600 /* seconds */ * 1000 /* ms */);
                    if (babyBirthDateSpecial.getTime() > date.getTime()) {
                        return 'Too old';
                    }
                }
                catch (_a) {
                    return 'Invalid';
                }
            },
            authorizedemail: function ($el) {
                var authorizedHosts = ['gmail.com', 'hotmail.fr', 'hotmail.com', 'yahoo.fr', 'orange.fr', 'live.fr', 'laposte.net', 'outlook.fr', 'sfr.fr', 'wanadoo.fr', 'free.fr', 'icloud.com', 'yahoo.com', 'msn.com', 'outlook.com', 'neuf.fr', 'gmx.fr', 'aol.com', 'bbox.fr', 'aol.fr', 'gmail.fr', 'ymail.com', 'highco.fr'];
                var emailMatch = $el.val().match(/^([\w-]+(?:\.[\w-]+)*)@(((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?))$/);
                if (!emailMatch) {
                    return 'Wrong format';
                }
                var hostMatch = emailMatch[2];
                if (authorizedHosts.indexOf(hostMatch) === -1) {
                    return 'Unrecognized email host';
                }
            }
        }
    });
    $form
        .on('submit', function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var close_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!e.isDefaultPrevented()) return [3 /*break*/, 1];
                        // on zappe la class
                        $('body').removeClass('formValid').addClass('formNotValid');
                        return [3 /*break*/, 3];
                    case 1:
                        e.preventDefault();
                        e.stopPropagation();
                        if (submitButton) {
                            submitButton.disabled = true;
                        }
                        return [4 /*yield*/, registerForm_1.postForm($(e.target).serializeArray())];
                    case 2:
                        close_1 = _a.sent();
                        if (close_1) {
                            // Hide the modal
                            $('#modalForm').modal('hide');
                            // si le form est ok, on cache le form, et on affiche les offres
                            $('body').removeClass('formNotValid').addClass('formValid');
                        }
                        if (submitButton) {
                            submitButton.disabled = false;
                        }
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    })
        .on('change', function (e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if ($form.validator('validate')) {
                    $('body').removeClass('formNotValid').addClass('formValid');
                    submitButton.disabled = false;
                }
                else {
                    // on zappe la class
                    $('body').removeClass('formValid').addClass('formNotValid');
                    submitButton.disabled = true;
                }
                return [2 /*return*/];
            });
        });
    });
    $("#modalForm input").on('keyup change focusout', function () {
        var NONEMPTY_CLASS = 'nonempty';
        if (this.value !== '') {
            $(this).addClass(NONEMPTY_CLASS);
        }
        else {
            $(this).removeClass(NONEMPTY_CLASS);
        }
    });
    if (window.location.hash === '#postimpression') {
        // Hide the modal
        $('#modalConfirmation').modal('show');
    }
});

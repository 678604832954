"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FlashMessage = /** @class */ (function () {
    function FlashMessage($container) {
        this.$container = $container;
    }
    Object.defineProperty(FlashMessage, "instance", {
        get: function () {
            if (!FlashMessage._instance) {
                FlashMessage._instance = new FlashMessage($('#modalError'));
            }
            return FlashMessage._instance;
        },
        enumerable: true,
        configurable: true
    });
    /*
    private get $flashes(){
        return this.$container.find('.flash');
    }
    */
    FlashMessage.addFlash = function (level, title, content, error) {
        FlashMessage.instance.addFlash(level, title, content, error);
    };
    FlashMessage.prototype.addFlash = function (level, title, content, error) {
        var consoleOutput = title + "     " + content;
        switch (level) {
            case 'info':
                console.info(consoleOutput, error);
                break;
            case 'warning':
                console.warn(consoleOutput, error);
                break;
            case 'danger':
                console.error(consoleOutput, error);
                break;
            default:
                console.log(consoleOutput, error);
                break;
        }
        //const contextClass = `alert-${level}`;
        /*const templateStr = `<div class="alert ${contextClass} alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Fermer"><span aria-hidden="true">&times;</span></button>
        <h4>${title}</h4>
        <p>${content}</p>
        </div>`;*/
        this.$container.find('h2').text(title);
        this.$container.find('#errorBody').empty().append("<p>" + content + "</p>");
        $('#modalError').modal('show');
    };
    return FlashMessage;
}());
exports.FlashMessage = FlashMessage;
